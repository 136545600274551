export default {
  fontFamily: "Futura, FuturaLight",

  h1: {
    fontWeight: 500,
    fontSize: 64,
    letterSpacing: "4px",
    lineHeight: "82.05px",
    textTransform: "uppercase"
  },
  h2: {
    fontWeight: 700,
    fontSize: 48,
    letterSpacing: "-1px",
    lineHeight: "64px",
    "@media (max-width:768px)": {
      fontSize: 40,
      letterSpacing: "-0.5px",
      lineHeight: "48px"
    }
  },
  h3: {
    fontFamily: "FuturaLight",
    fontWeight: 300,
    fontSize: 18,
    letterSpacing: "0.5px",
    lineHeight: "24px"
  }
}
