import { createMuiTheme } from "@material-ui/core"
import typography from "./typography"
import FuturaMedium from "../fonts/futura/FuturaPT-Medium/font.woff"
import FuturaMedium2 from "../fonts/futura/FuturaPT-Medium/font.woff2"
import FuturaLight from "../fonts/futura/FuturaPT-Light/font.woff"
import FuturaLight2 from "../fonts/futura/FuturaPT-Light/font.woff2"
import FuturaBold from "../fonts/futura/FuturaPT-Bold/font.woff"
import FuturaBold2 from "../fonts/futura/FuturaPT-Bold/font.woff2"

const futura = {
  fontFamily: "Futura",
  fontStyle: "normal",
  fontWeight: 400,
  src: `
    url(${FuturaMedium}) format('woff'),
    url(${FuturaMedium2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
}

const futuraLight = {
  fontFamily: "FuturaLight",
  fontStyle: "normal",
  fontWeight: 300,
  src: `
    url(${FuturaLight}) format('woff'),
    url(${FuturaLight2}) format('woff2')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
}

const futuraBold = {
  fontFamily: "FuturaBold",
  fontStyle: "normal",
  fontWeight: 300,
  src: `
    url(${FuturaBold}) format('woff'),
    url(${FuturaBold2}) format('woff2')
  `,
  unicodeRange:
      "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF"
}

const theme = createMuiTheme({
  typography,
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [futura, futuraLight, futuraBold]
      }
    }
  }
})

export default theme

